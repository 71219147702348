body{
  font-family: "Barlow-Regular","sans-serif";
}

body, html {
    height: 100%;
    max-width: 100%;
    margin: 0;
}

h3{
    font-family: 'AlfaSlabOne';
    font-weight: 400;
    text-transform: uppercase;
}

p{
    font-weight: 200;
}

.start-container{
    height: 100%;
}

.text,
.container{
    max-width: 100%;
    height: 100%;
    margin: 0 !important;
}

.logo{
    text-align: right;
    font-size: 1rem;
    //font-weight: 700;
    margin-right: 1em;

    position: relative;
    bottom: 85px;
    float: right;
    color: white;
    font-family: AlfaSlabOne, sans-serif;
    clear: both;
}

.parallax {
  display: block;
  img{
    display: block;
    width: 100%;
    max-width: 100%;
  }
}

.shop_button {
  position: relative;
  bottom: 95px;
  float: right;
  margin-right: 1em;

  background-color: red;
  border-radius: 5px;
  padding: 1em 2em;

  a {
    display: block;
    text-decoration: none;
    color: white;
    font-family: AlfaSlabOne, sans-serif;
    //font-weight: 700;
    font-size: calc(2 * var(--fontsize));
  }
}

.content-container{
    background-color: rgba(255,255,255,0.75);
    /*
    position: relative;
    position: absolute;
    bottom: 150px;
    */
    position: relative;
    top: 45vh;
    width: 100%;
    height: max-content;

    &.large {
      top: 20vh;
    }
}

.content{
    width: 95%;
    display: flex;
    flex-direction: row;
    padding: 0 2.5%;
}

.bottle_mobile {
    display: block;
    img{
        position: relative;
        left: 0px;
        //height: 250px;
        //top: -50px;
        max-width: 100px;
    }
}
.bottle {
  display: none;
}

.amulett{
    display: none;
    img{
      position: absolute;
      left: 0px;
      height: 150px;
      top: -50px;
    }
}

.table-cell {
  //width: 65%;
  flex-grow: 5;
}



.start{
    position: fixed;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    z-index: -100;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background: url(/assets/images/bubbles.jpg) no-repeat;
    background-size: cover;
}

.start-image.start-bottle {
    left: 0;
    z-index: 2;
    margin-left: auto;
    margin-right: auto;
    right: -20%;
}

.start-image.start-amulett {
    left: -20%;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
    right: 0;
}

.start-image {
    position: absolute;
    display: none;
    top: 10%;
}

#fp-nav.right {
    right: 17px;
}

#fp-nav {
    position: fixed;
    z-index: 100;
    margin-top: -32px;
    top: 50%;
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
}

#fp-nav ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

#fp-nav ul li {
    display: block;
    width: 14px;
    height: 13px;
    margin: 7px;
    position: relative;
}

#fp-nav ul li a {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
}

#fp-nav ul li a.active span, #fp-nav ul li:hover a.active span {
    height: 18px;
    width: 18px;
    margin: -9px 0 0 -9px;
    border-radius: 100%;
}

#fp-nav ul li a span {
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    height: 12px;
    width: 12px;
    border: 0;
    background: #333;
    left: 50%;
    top: 50%;
    margin: -6px 0 0 -6px;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
}

@media only screen and (min-width: 1125px) {
    .logo{
      margin-top: 2em;
      padding-bottom: 0;
      bottom: 60px;
    }

    .content{
    }
    
    .bottle {
        width: 10%;
        display: inline-block;
        
        img{
            position: absolute;
            right: 0px;
            left: unset;
            height: 250px;
            top: -50px;
        }
    }
    .bottle_mobile {
      display: none;
    }
    
    .amulett{
        width: 15%;
        display: inline-block;
        img{
            position: absolute;
            left: 0px;
            height: 200px;
            top: -50px;
        }

    }

    .container{
        height: 100%;
        position: relative;
        width: 100%;
    }

    .table-cell {
      width: 75%;
    }

    .content-container{
        position: absolute;
        bottom: 150px;
        top: initial;
    }
}

.parallax { 

    /* Full height */
    height: 100%; 
    min-width: 100%;
    min-height: 100%;

    /* Create the parallax scrolling effect */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;

    img {
      width: unset;
    }

}
